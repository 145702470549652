

/* image */
.image-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .add-image-button {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-image-button:hover {
    background-color: #0056b3;
  }
  
  .image-preview-container {
    position: relative;
  }
  
  .image-preview {
    max-width: 200px;
    height: auto;
    margin-bottom: 10px;
  }
  
  .image-upload-container input[type='file'] {
    display: none;
  }