/* Hide the checkbox by default */
/* .checkbox-hidden {
  display: none;
} */
.image-container input[type="checkbox"] {
    width: 20px;
    height: 20px;
  }
  
  .checkbox-visible:hover .checkbox-hidden {
    display: block;
  
  }
  
  
  .image-container {
    position: relative;
  }
  
  .image-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(136, 136, 136, 0.5);
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .checkbox-visible:hover .image-container::before {
    opacity: 1;
  }
  
  .checkbox-visible:hover input[type="checkbox"] {
    opacity: 1 !important;
  }
  
  
  