@media print {
    .print-application {
        display: flex !important;
    }
}

@media only screen and (max-width: 425px) {
    .print-application {
        display: flex;
        justify-content: center;
    }
}